import '@radix-ui/themes/styles.css';

import { Theme as RadixUiTheme } from '@radix-ui/themes';
import { AnimatePresence } from 'framer-motion';
import { GeistSans } from 'geist/font/sans';
import ProgressBar from 'nextjs-progressbar';
import { Suspense, useEffect, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { CommandBar } from '@/components';
import { ThemeProvider } from '@/contexts';
import { PostHogHocProvider } from '@/hocs/posthog';
import { useTheme } from '@/hooks';
import GlobalStyles from '@/styles/global';

function AnalyticsProviders({ children }: { children: React.ReactNode }) {
  return (
    <PostHogHocProvider>
      {children}
    </PostHogHocProvider>
  );
}

export function AppProvider({ children }: { children: React.ReactNode }) {
  const { theme, updateTheme } = useTheme();
  const [defaultTheme, setDefaultTheme] = useState<'dark' | 'light' | null>(
    null,
  );

  useEffect(() => {
    setDefaultTheme(theme.title === 'DARK_THEME' ? 'dark' : 'light');
  }, [theme]);

  return (
    <AnalyticsProviders>
      <ProgressBar
        color={theme.colors.highlightColor}
        options={{
          showSpinner: false,
          easing: 'ease',
          speed: 200,
          trickleSpeed: 200,
        }}
        height={3}
        startPosition={0.3}
        stopDelayMs={200}
        showOnShallow={true}
      />
      <AnimatePresence
        mode="wait"
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        <RadixUiTheme appearance={defaultTheme ? defaultTheme : 'dark'}>
          <StyledComponentsThemeProvider theme={theme}>
            <main className={GeistSans.className}>
              <ThemeProvider updateTheme={updateTheme}>
                <GlobalStyles />

                <Suspense>
                  <CommandBar>{children} </CommandBar>
                </Suspense>
              </ThemeProvider>
            </main>
          </StyledComponentsThemeProvider>
        </RadixUiTheme>
      </AnimatePresence>
    </AnalyticsProviders>
  );
}
